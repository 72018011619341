$color__white: #FFFFFF;
$color__light-gray: #EEEEEE;
$color__soft-gray: #D0D0D0;
$color__medium-gray: #A3A3A3;
$color__gray: #777777;
$color__hard-gray: #4E4E4E;
$color__very-hard-gray: #292929;
$color__black: #000000;

$color__admin-development: #721C24;
$color__admin-staging: #856404;
$color__admin-production: #155724;

$color__login-background: #00ACB3;

$color__cookie-text: #0E1358;
$color__cookie-background-button: #0E1358;
$color__cookie-background-button-allow: #9CD9D9;

body {
  --wp-theme-woocommerce-color-text: #322A7D;

  /* OLD */
  --wp-theme-woocommerce-color-background: #F7F8FA;
  --wp-theme-woocommerce-color-background-bis: #FFFFFF;
  --wp-theme-woocommerce-color-background-container: #FFFFFF;
  --wp-theme-woocommerce-color-border-table: #98A7C9;
  --wp-theme-woocommerce-color-background-button: #E0E4EB;
  --wp-theme-woocommerce-color-button: #322A7D;
  --wp-theme-woocommerce-color-background-button--hover: #322A7D;
  --wp-theme-woocommerce-color-button--hover: #FFFFFF;
  --wp-theme-cookies-modal-color: #0E1358;
  --wp-theme-cookies-modal-bgcolor: #ffffff;
  --wp-theme-cookies-bottom-alert-color: #0E1358;
  --wp-theme-cookies-bottom-alert-bgcolor: #ffffff;
  --wp-theme-cookies-btn1-color: #ffffff;
  --wp-theme-cookies-btn1-bgcolor: #0E1358;
  --wp-theme-cookies-btn2-color: #0E1358;
  --wp-theme-cookies-btn2-bgcolor: #9CD9D9;
  --wp-theme-cookies-btn3-color: #ffffff;
  --wp-theme-cookies-btn3-bgcolor: #EA753D;
  --wp-theme-cookies-btn-accept-color: #6C757D;
  --wp-theme-cookies-btn-accept-no-color: #67baba;
  --wp-theme-cookies-btn-accept-bgcolor: #CED4DA;
  --wp-theme-cookies-btn-accept-disabled-color: #b7dede;
  --wp-theme-cookies-btn-accept-disabled-bgcolor: #d0eded;
}
